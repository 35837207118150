import React, {useEffect, useState} from 'react';
import ScrollSpy from 'react-scrollspy';
import { Link } from 'gatsby';
import { FiMenu, FiX } from "react-icons/fi";
import {StaticImage} from "gatsby-plugin-image";

const Header = () => {

    useEffect(() => {
        window.addEventListener('scroll', function() {
            let value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        let elements = document.querySelectorAll('.has-droupdown > a');

        for(let i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
    });

    const menuTrigger = () => {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    };

    const closeMenuTrigger = () => {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    };

    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

    const dropdownRef = React.createRef();

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsSubmenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const handleDropdownClick = (e) => {
        if (!isSubmenuOpen) {
            e.preventDefault();
            setIsSubmenuOpen(true);
        }
    };

    return (
        <header className="header-area formobile-menu header--fixed default-color">
            <div className="header-wrapper" id="header-wrapper">
                <div className="header-left">
                    <div className="logo">
                        <Link to="/">
                        </Link>
                    </div>
                </div>
                <div className="header-right">
                    <nav className="mainmenunav d-lg-block">
                        <ScrollSpy className="mainmenu"
                                   items={[]}
                                   currentClassName="is-current"
                                   offset={-200}
                        >

                            <li>
                                <Link to="/">Prva stran</Link>
                            </li>

                            <li className="has-droupdown" ref={dropdownRef}>
                                <Link to="/obisci-rudnik/glavni-rov" className="" onClick={handleDropdownClick}>Obišči rudnik</Link>
                                <ul className={`submenu ${isSubmenuOpen ? 'active' : ''}`}>
                                    <li><Link to="/obisci-rudnik/glavni-rov">Glavni rov</Link></li>
                                    <li><Link to="/obisci-rudnik/izvozni-rov">Izvozni rov</Link></li>
                                    <li><Link to="/obisci-rudnik/sitarjevski-hodi">
                                            <span className="flex align-items-center justify-content-center">
                                                <span className="pr-1">Sitarjevški hodi</span>
                                                <span className=" badge">NOVO</span>
                                            </span>
                                        </Link>
                                    </li>
                                    <li><Link to="/obisci-rudnik/animirana-vodenja">Animirana vodenja</Link></li>
                                    <li><Link to="/obisci-rudnik/kulinarika">Kulinarika</Link></li>
                                    <li><Link to="/obisci-rudnik/tradicionalni-dogodki">Dogodki</Link></li>
                                    <li><Link to="/obisci-rudnik/trgovina-in-spominki">Trgovina in spominki</Link></li>
                                </ul>
                            </li>

                            <li><Link to="/razisci-rudnik-sitarjevec">Razišči rudnik</Link></li>
                            <li><Link to="/cenik">Cenik</Link></li>

                            <li className="has-droupdown">
                                <Link to="/kontakt-in-informacije" className="">Kontakt in informacije</Link>
                                <ul className="submenu">
                                    <li><Link to="/izjava-o-dostopnosti">Izjava o dostopnosti</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to={"/en"} className="country-flag">
                                    <StaticImage
                                        src="../../assets/images/rudnik/gb.png"
                                        alt="English pages"
                                        placeholder="blurred"
                                        imgClassName="img-fluid w-100"
                                        loading="lazy"
                                    />
                                </Link>
                            </li>
                        </ScrollSpy>
                    </nav>
                    {/* Start Humberger Menu  */}
                    <div className="humberger-menu d-block d-lg-none pl--20">
                        <span onClick={menuTrigger} className="menutrigger text-white"><FiMenu/></span>
                    </div>
                    {/* End Humberger Menu  */}
                    <div className="close-menu d-block d-lg-none">
                        <span onClick={closeMenuTrigger} className="closeTrigger"><FiX/></span>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;
